import request from "@/utils/request.js";

export default {
	// 删除图片
	deletePic(obj) {
		return request({
			url: "/deletePic",
			method: "post",
			data: {
				picUrl: obj,
			},
		});
	},
	// 上传图片
	uploadPic(obj) {
		return request({
			url: "/uploadPic",
			method: "post",
			data: obj,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	},
};
