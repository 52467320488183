<template>
  <div class="upload">
    <el-upload
      action="//my.gudemanage.com/api/uploadPic"
      class="avatar-uploader"
      :data="{ name: picName, companyId: companyId, path: path }"
      :accept="accept"
      :headers="uploadHeader"
      :showFileList="false"
      :on-success="handleAvatarSuccess"
      :on-error="handleAvatarError"
      :multiple="false"
    >
      <img v-if="type == 'img'" v-show="pic" :src="pic" class="avatar" />
      <video v-else-if="type == 'video'" :src="pic" class="avatar"></video>
      <div class="full-pic" v-show="!pic">
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <span>{{ text }}</span>
      </div>
    </el-upload>
    <slot name="pic_alt"></slot>
    <i v-show="pic" @click="delPic" class="el-icon-error"></i>
  </div>
</template>

<script>
import service from "@/api/resource.js"
export default {
  props: {
    text: {
      type: String,
      default: "上传图片",
    },
    picName: {
      type: String,
    },
    accept: {
      type: String,
      default: "image/png,image/jpg,image/jpeg",
    },
    pic: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "img",
    },
    path: {
      type: String,
    },
  },
  data() {
    return {
      // token
      uploadHeader: {
        authorization: sessionStorage.getItem("authorization"),
      },
      companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
    }
  },
  methods: {
    // 上传缩略图片成功
    handleAvatarSuccess(res) {
      this.$message({
        message: "上传成功！",
        type: "success",
      })
      this.$emit("uploadSuccess", {
        url: "//test.gudemanage.com/" + res.data[0].url,
        picName: this.picName,
      })
    },
    // 上传图片失败
    handleAvatarError() {
      this.$message.error("上传失败！")
    },
    // 删除图片
    delPic() {
      const that = this
      this.$confirm("是否删除该资源?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          service
            .deletePic(that.pic.split("?")[0])
            .then((res) => {
              if (res.status == 200) {
                that.$message({
                  type: "success",
                  message: res.data.result,
                })
                that.$emit("deleteSuccess", that.picName)
              }
            })
            .catch((error) => {
              that.$message.error(error)
            })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss">
$fontColor: #333333;
$fontShallow: #cccccc;

.upload {
  position: relative;
  margin-right: 20px;

  .full-pic {
    width: 100px;
    height: 75px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 1px dashed #e8e8e8;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $fontShallow;
    font-size: 16px;
    line-height: initial;

    > i {
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  .el-icon-error {
    position: absolute;
    right: -15px;
    top: -15px;
    font-size: 28px;
    color: red;
    cursor: pointer;
  }

  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
