<template>
  <div class="basics">
    <div class="basics_label flex">
      <p class="basics_label-left">网站基础设置</p>
      <div class="basics_label-right">
        <el-button
          size="medium"
          type="primary"
          @click="save"
          :loading="saveLoad"
          :disabled="saveBtnDisabled"
          >保存</el-button
        >
      </div>
    </div>
    <div class="basics_content-item flex">
      <p class="basics_conent-item-sort flex">01&nbsp;&nbsp;基础设置</p>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-input">
        <p>公司名称</p>
        <el-input
          placeholder="请填写公司名称"
          v-model="company"
          disabled
        ></el-input>
        <p>网站标题</p>
        <el-input
          placeholder="格式如：关键词-关键词-公司名称"
          v-model="website.title"
        ></el-input>
        <p>网站域名</p>
        <el-input
          placeholder="如您有自己的域名请在此处填写"
          v-model="website.domainUrl"
        ></el-input>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-img flex">
        <p>上传logo</p>
        <Upload
          text="完整LOGO"
          picName="logo"
          path="website"
          :pic="website.logo"
          @uploadSuccess="uploadSuccess"
          @deleteSuccess="deleteSuccess"
        />
        <Upload
          text="LOGO标志"
          picName="smallLogo"
          path="website"
          :pic="website.smallLogo"
          @uploadSuccess="uploadSuccess"
          @deleteSuccess="deleteSuccess"
        />
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-img flex">
        <p>浏览器图标</p>
        <Upload
          text="上传图标"
          picName="icoPic"
          path="website"
          :pic="website.icoPic"
          @uploadSuccess="uploadSuccess"
          @deleteSuccess="deleteSuccess"
        />
      </div>
    </div>
    <div class="basics_content-item flex">
      <p class="basics_conent-item-sort flex">
        02&nbsp;&nbsp;V&nbsp;&nbsp;R&nbsp;&nbsp;相关
      </p>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-input">
        <p>
          VR空间链接
          <el-button type="text" @click="toVR(website.vrUrl)">查看</el-button>
        </p>
        <el-input v-model="website.vrUrl" disabled></el-input>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-img flex">
        <p>上传全景图</p>
        <Upload
          picName="fullVrPic"
          path="website"
          :pic="website.fullVrPic"
          @uploadSuccess="uploadSuccess"
          @deleteSuccess="deleteSuccess"
        />
      </div>
    </div>
    <div class="basics_content-item flex">
      <p class="basics_conent-item-sort flex">03&nbsp;&nbsp;其他内容</p>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-img flex">
        <p>首页Banner图</p>
        <Upload
          path="website"
          v-if="
            website.bannerPic[0] &&
              website.bannerPic[0].picName === 'bannerPic1'
          "
          :pic="website.bannerPic[0].url"
          :picName="website.bannerPic[0].picName"
          @uploadSuccess="uploadSuccess"
          @deleteSuccess="deleteSuccess"
        />
        <Upload
          path="website"
          v-else
          picName="bannerPic1"
          @uploadSuccess="uploadSuccess"
        />
        <Upload
          path="website"
          v-if="
            website.bannerPic[1] &&
              website.bannerPic[1].picName === 'bannerPic2'
          "
          :pic="website.bannerPic[1].url"
          :picName="website.bannerPic[1].picName"
          @uploadSuccess="uploadSuccess"
          @deleteSuccess="deleteSuccess"
        />
        <Upload
          path="website"
          v-else
          picName="bannerPic2"
          @uploadSuccess="uploadSuccess"
        />
        <Upload
          path="website"
          v-if="
            website.bannerPic[2] &&
              website.bannerPic[2].picName === 'bannerPic3'
          "
          :pic="website.bannerPic[2].url"
          :picName="website.bannerPic[2].picName"
          @uploadSuccess="uploadSuccess"
          @deleteSuccess="deleteSuccess"
        />
        <Upload
          path="website"
          v-else
          picName="bannerPic3"
          @uploadSuccess="uploadSuccess"
        />
        <Upload
          path="website"
          v-if="
            website.bannerPic[3] &&
              website.bannerPic[3].picName === 'bannerPic4'
          "
          :pic="website.bannerPic[3].url"
          :picName="website.bannerPic[3].picName"
          @uploadSuccess="uploadSuccess"
          @deleteSuccess="deleteSuccess"
        />
        <Upload
          path="website"
          v-else
          picName="bannerPic4"
          @uploadSuccess="uploadSuccess"
        />
      </div>
    </div>
    <div class="basics_content-item flex">
      <p class="basics_conent-item-sort flex">04&nbsp;&nbsp;企业概况</p>
      <el-divider direction="vertical"></el-divider>

      <div class="basics_content-item-input">
        <el-input
          type="textarea"
          :rows="3"
          v-model="website.desc"
          placeholder="请输入公司简介"
        >
        </el-input>
      </div>
      <div class="basics_content-item-img flex">
        <Upload
          text="图片或视频"
          accept="image/png,image/jpg,image/jpeg,video/mp4"
          picName="descPic"
          path="website"
          :pic="website.descUrl"
          :type="descUrlType"
          @uploadSuccess="uploadSuccess"
          @deleteSuccess="deleteSuccess"
        />
      </div>
    </div>
    <div class="basics_content-item flex">
      <p class="basics_conent-item-sort flex">05&nbsp;&nbsp;联系信息</p>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-form">
        <el-form label-position="right" label-width="3rem">
          <el-form-item
            :label="index == 0 ? '电话' : '  '"
            v-for="(item, index) in website.telList"
            :key="index"
          >
            <el-select v-model="item.value" placeholder="电话类型">
              <el-option
                v-for="item in telOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input v-model="item.tel" placeholder="电话号码" />
            <div style="width: 4rem; text-align: left">
              <el-button
                type="text"
                v-show="addTelShow < 4 && index == 0"
                @click="addTel"
                >+ 添加电话</el-button
              >
              <i
                class="el-icon-remove-outline"
                style="color: red; margin-left: 20px"
                v-show="!index == 0"
                @click="delTel(index)"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input
              placeholder="请输入电子邮箱"
              v-model="website.email"
              style="width: 9.1rem"
          /></el-form-item>
        </el-form>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-form">
        <el-form label-position="right" label-width="3rem">
          <el-form-item label="二维码">
            <Upload
              picName="QRcode"
              path="website"
              :pic="website.QRcode.url"
              @uploadSuccess="uploadSuccess"
              @deleteSuccess="deleteSuccess"
            >
              <el-input
                slot="pic_alt"
                placeholder="名称"
                v-model="website.QRcode.name"
                style="width: 4rem"
              />
            </Upload>
            <Upload
              picName="QRcode2"
              path="website"
              :pic="website.QRcode2.url"
              @uploadSuccess="uploadSuccess"
              @deleteSuccess="deleteSuccess"
            >
              <el-input
                slot="pic_alt"
                placeholder="名称"
                v-model="website.QRcode2.name"
                style="width: 4rem"
              />
            </Upload>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="basics_content-item flex">
      <p class="basics_conent-item-sort flex">06&nbsp;&nbsp;详细地址</p>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_conent-item-address">
        <baidu-map
          class="basics_conent-item-address-map"
          :center="mapCenter"
          :zoom="mapZoom"
          :scroll-wheel-zoom="true"
          @ready="handlerBMap"
        />
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-input">
        <p>地址</p>
        <el-autocomplete
          v-model="mapLocation.address"
          :fetch-suggestions="querySearch"
          placeholder="请输入详细地址"
          :trigger-on-focus="false"
          @select="handleSelect"
        />
        <p>
          lng: {{ website.mapMarker.lng || 0 }}，lat:
          {{ website.mapMarker.lat || 0 }}
        </p>
      </div>
    </div>
    <div class="basics_content-item flex">
      <p class="basics_conent-item-sort flex">07&nbsp;&nbsp;友情链接</p>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-form">
        <el-form label-width="1rem">
          <el-form-item
            :label="index + 1 + ''"
            v-for="(item, index) in website.friendLink"
            :key="index"
          >
            <el-input
              placeholder="请输入名称"
              style="width: 4rem; margin-right: 20px"
              v-model="item.name"
            />
            <el-input
              placeholder="请输入链接"
              v-model="item.linkUrl"
              style="width: 10rem"
            />
            <i
              class="el-icon-remove-outline"
              style="color: red; font-size: 20px; margin-left: 20px"
              v-show="!index == 0"
              @click="delLink(index)"
            ></i>
          </el-form-item>
          <el-form-item>
            <el-button type="text" style="margin-left: 40px" @click="addLink"
              >+ 继续添加</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="basics_content-item flex">
      <p class="basics_conent-item-sort flex">08&nbsp;&nbsp;备案管理</p>
      <el-divider direction="vertical"></el-divider>
      <div class="basics_content-item-input">
        <p>网站备案号</p>
        <el-input
          placeholder="请填写网站备案号"
          v-model="website.provider"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import Upload from "@/components/Upload.vue"
import api from "@/api/website.js"

export default {
  name: "Basics",
  data() {
    return {
      // 公司名称
      company: "",
      // 网站基础信息
      website: {
        title: "",
        domainUrl: "",
        logo: "",
        smallLogo: "",
        icoPic: "",
        vrUrl: "",
        fullVrPic: "",
        bannerPic: [],
        desc: "",
        descUrl: "",
        telList: [{ value: "", tel: "" }],
        email: "",
        QRcode: { url: "", name: "" },
        QRcode2: { url: "", name: "" },
        mapMarker: { lng: 0, lat: 0 },
        // 友情链接
        friendLink: [{ name: "", linkUrl: "" }],
        provider: "",
      },
      // 电话类型
      telOptions: [
        { value: "固定电话", label: "固定电话" },
        { value: "传真号码", label: "传真号码" },
        { value: "热线电话", label: "热线电话" },
      ],
      mapCenter: { lng: 113.750391, lat: 23.031208 },
      mapZoom: 16,
      mapLocation: {
        address: undefined,
        coordinate: undefined,
      },
      // 企业简介的资源属性
      descUrlType: "img",
      // 提交按钮loading状态
      saveLoad: false,
      selectedOptions: [],
      // 等待获取信息接口请求完成，才开放保存按钮
      saveBtnDisabled: true,
    }
  },
  methods: {
    // 添加电话
    addTel() {
      if (this.addTelShow > 4) {
        return
      }
      this.website.telList.push({ value: "", tel: "" })
    },
    // 删除电话
    delTel(val) {
      this.website.telList.splice(val, 1)
    },
    // 添加友链
    addLink() {
      this.website.friendLink.push({ name: "", linkUrl: "" })
    },
    // 删除链接
    delLink(val) {
      this.website.friendLink.splice(val, 1)
    },
    // 上传图片成功
    uploadSuccess(val) {
      val.url = val.url + "?" + Math.floor(Math.random() * 100) + 0
      switch (val.picName) {
        case "logo":
          this.website.logo = val.url
          break
        case "smallLogo":
          this.website.smallLogo = val.url
          break
        case "icoPic":
          this.website.icoPic = val.url
          break
        case "fullVrPic":
          this.website.fullVrPic = val.url
          break
        case "bannerPic1":
          this.website.bannerPic[0] = val
          break
        case "bannerPic2":
          this.website.bannerPic[1] = val
          break
        case "bannerPic3":
          this.website.bannerPic[2] = val
          break
        case "bannerPic4":
          this.website.bannerPic[3] = val
          break
        case "descPic":
          this.website.descUrl = val.url
          if (val.url.indexOf(".mp4") > 0) {
            this.descUrlType = "video"
          } else {
            this.descUrlType = "img"
          }
          break
        case "QRcode":
          this.website.QRcode.url = val.url
          break
        case "QRcode2":
          this.website.QRcode2.url = val.url
          break
      }
    },
    // 删除图片成功
    deleteSuccess(val) {
      switch (val) {
        case "logo":
          this.website.logo = ""
          break
        case "smallLogo":
          this.website.smallLogo = ""
          break
        case "icoPic":
          this.website.icoPic = ""
          break
        case "fullVrPic":
          this.website.fullVrPic = ""
          break
        case "descPic":
          this.website.descUrl = ""
          break
        case "QRcode":
          this.website.QRcode.url = ""
          break
        case "QRcode2":
          this.website.QRcode2.url = ""
          break
        default:
          for (let index = 0; index < this.website.bannerPic.length; index++) {
            if (
              this.website.bannerPic[index] &&
              this.website.bannerPic[index].picName === val
            ) {
              this.website.bannerPic[index] = null
              break
            }
          }
          this.website.bannerPic = JSON.parse(
            JSON.stringify(this.website.bannerPic)
          )
          break
      }
    },
    // 获取概况数据
    async getWebInfo() {
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
      }
      try {
        const res = await api.getWebInfo(condition)
        if (res.data.code == 200) {
          if (res.data.data) {
            if (res.data.data.descUrl) {
              if (res.data.data.descUrl.indexOf(".mp4") > 0) {
                this.descUrlType = "video"
              } else {
                this.descUrlType = "img"
              }
            }
            // 保存模型id
            this.$store.commit("setModelId", res.data.data.modelId || "")
            // 保存seo优化信息
            this.$store.commit("setSeoContent", {
              seoTitle: res.data.data.seoTitle || "",
              seoKeyword: res.data.data.seoKeyword || "",
              seoDesc: res.data.data.seoDesc || "",
            })
            // 保存留言板功能信息
            this.$store.commit("setBoardData", {
              boardShow: res.data.data.boardShow,
              boardBar: res.data.data.boardBar || "",
              levitateShow: res.data.data.levitateShow,
            })
            for (let key in res.data.data) {
              if (res.data.data[key]) {
                // 非字符串数据类型需转
                if (
                  key == "bannerPic" ||
                  key == "telList" ||
                  key == "QRcode" ||
                  key == "QRcode2" ||
                  key == "mapMarker" ||
                  key == "friendLink"
                ) {
                  this.website[key] = JSON.parse(res.data.data[key])
                } else {
                  this.website[key] = res.data.data[key]
                }
              }
            }
            this.saveBtnDisabled = false
            localStorage.setItem("webUrl", this.website.webUrl)
          }
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 保存概况数据
    async save() {
      this.saveLoad = true
      try {
        let condition = this.website
        const res = await api.saveWebInfo(condition)
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功！",
            duration: 1000,
          })
          this.saveLoad = false
        } else {
          this.$message.error("网络故障！")
          this.saveLoad = false
        }
      } catch (error) {
        this.$message.error(error)
        this.saveLoad = false
      }
    },
    handlerBMap({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      if (this.mapLocation.coordinate && this.mapLocation.coordinate.lng) {
        this.website.mapMarker.lng = this.mapLocation.coordinate.lng
        this.website.mapMarker.lat = this.mapLocation.coordinate.lat
        this.mapCenter.lng = this.mapLocation.coordinate.lng
        this.mapCenter.lat = this.mapLocation.coordinate.lat
        this.mapZoom = 16
        map.addOverlay(new this.BMap.Marker(this.mapLocation.coordinate))
      } else {
        this.website.mapMarker.lng = 113.750391
        this.website.mapMarker.lat = 23.031208
        this.mapCenter.lng = 113.750391
        this.mapCenter.lat = 23.031208
        this.mapZoom = 16
      }
    },
    querySearch(queryString, cb) {
      var that = this
      var myGeo = new this.BMap.Geocoder()
      myGeo.getPoint(
        queryString,
        function(point) {
          if (point) {
            that.mapLocation.coordinate = point
            that.makerCenter(point)
          } else {
            that.mapLocation.coordinate = null
          }
        },
        this.locationCity
      )
      var options = {
        onSearchComplete: function(results) {
          if (local.getStatus() === 0) {
            // 判断状态是否正确
            var s = []
            for (var i = 0; i < results.getCurrentNumPois(); i++) {
              var x = results.getPoi(i)
              var item = { value: x.address + x.title, point: x.point }
              s.push(item)
              cb(s)
            }
          } else {
            cb()
          }
        },
      }
      var local = new this.BMap.LocalSearch(this.map, options)
      local.search(queryString)
    },
    handleSelect(item) {
      var { point } = item
      this.mapLocation.coordinate = point
      this.makerCenter(point)
    },
    makerCenter(point) {
      if (this.map) {
        this.map.clearOverlays()
        this.map.addOverlay(new this.BMap.Marker(point))
        this.website.mapMarker.lng = point.lng
        this.website.mapMarker.lat = point.lat
        this.mapCenter.lng = point.lng
        this.mapCenter.lat = point.lat
        this.mapZoom = 16
      }
    },
    // 查看vr
    toVR(val) {
      window.open(val)
    },
  },
  components: {
    Upload,
  },
  computed: {
    // 添加电话按钮，最多为4个
    addTelShow() {
      return this.website.telList.length
    },
  },
  mounted() {
    // 获取基本信息
    this.getWebInfo()
    // 获取公司名称
    this.company = JSON.parse(localStorage.getItem("userInfo")).company
  },
}
</script>

<style lang="scss" scoped>
$fontColor: #333333;
$fontShallow: #cccccc;
$bgColor: #ffffff;

.basics {
  padding-bottom: 40px;
  padding-top: 80px;
  &_label {
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    position: fixed;
    width: calc(100% - 225px);
    left: 220px;
    top: 60px;
    z-index: 100;
    background-color: #fff;

    &-left {
      border-left: 6px solid #31b1ff;
      padding-left: 5px;
    }
  }

  &_content-item {
    min-height: 140px;
    margin: 10px 20px;
    background-color: $bgColor;
    border-radius: 10px;
    align-items: center;
    justify-content: flex-start;
    color: $fontColor;

    > div {
      min-height: 80px;
      padding: 20px 0;
    }

    .basics_conent-item-sort {
      width: 200px;
      font-size: 18px;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      padding: 0 40px;
    }

    .basics_content-item-input {
      padding: 20px 40px 20px 20px;
      text-align: left;

      > p {
        text-align: left;
        color: $fontColor;
        margin: 10px 0;
      }

      .el-textarea {
        width: 14rem;
      }

      .el-autocomplete {
        min-width: 14rem;
      }
    }

    .basics_content-item-img {
      align-items: center;
      > p {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 30px;
      }
    }

    .basics_content-item-form {
      margin-right: 40px;
      .el-form-item {
        display: flex;
        align-items: center;
        margin-top: 20px;
      }

      /deep/ .el-form-item__content {
        display: flex;
        align-items: center;
        margin: 0 !important;
        margin-top: 10px;

        .el-select {
          margin-right: 20px;
        }

        .el-button {
          margin-left: 20px;
        }

        .el-input {
          width: 200px;
        }
      }
    }

    .basics_conent-item-address {
      padding: 20px 40px 40px 40px;

      .basics_conent-item-address-map {
        width: 525px;
        height: 160px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
