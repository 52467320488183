import request from "@/utils/request.js"

export default {
  // 获取概况
  getWebInfo(obj) {
    return request({
      url: "/website/getWebInfo",
      method: "post",
      data: obj,
    })
  },
  // 创建概况
  createWebInfo(obj) {
    return request({
      url: "/website/createWebInfo",
      method: "post",
      data: obj,
    })
  },
  // 保存概况
  saveWebInfo(obj) {
    obj.companyId = JSON.parse(localStorage.getItem("userInfo")).companyId
    obj.address = JSON.parse(localStorage.getItem("userInfo")).address
    obj.company = JSON.parse(localStorage.getItem("userInfo")).company
    return request({
      url: "/website/saveWebInfo",
      method: "post",
      data: obj,
    })
  },
  // 获取模型列表
  getModelList() {
    return request({
      url: "/website/getModelList",
      method: "get",
    })
  },
  // 生成官网模板
  buildTemplate(obj) {
    return request({
      url: "/website/buildTemplate",
      method: "post",
      data: obj,
    })
  },
}
